.navbar {
    position: fixed;
    width: 100%;
    height: 11vh;
    left: 0px;
    top: 0px;

    display: grid;
    grid-template-columns: 1fr 19fr;
    align-content: center;

    background-color: #E6BE99;
    /* z-index: 20; */
}

/* Logo Cell */
#logo-image {
    position: relative;
    left: 20px;
    height: 6vh;
}

.nav-buttons {
    font-family: Larish Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;

    height: 40px;

    border: none;
    background: #E6BE99; 
    color: #464242
}

.nav-buttons:hover {
    color: #FFEFE0;
    cursor: pointer;
}

.toolbar {
    /* grid-column: 2/3; */
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-right: 22px;

    justify-self: right;
}


/* Personal B Cell */
.personal-button {
    align-self: center;
    width: 70px;
}

/* Will be removed when personal page finished */
.personal-button:hover {
    color: #914444;
}


/* Professional B Cell */
.professional-button {
    align-self: center;
    width: 100px;
}


/* Resume B Cell */
.resume-button {
    align-self: center;
    width: 90px;
    
    border: 2px solid #FFEFE0;
    box-sizing: border-box;
    border-radius: 11px;
    color: #FFEFE0;
}

.resume-button:hover {
    color: #E6BE99;
    background-color: #FFEFE0;
}
