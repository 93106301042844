.scoreboard {
    position: fixed;
    top: 10vh;
    left: 20vw;

    display: grid;
    grid-template-rows: 35px 140px 35px;
    row-gap: 10px;
    justify-items: center;
    align-items: center;

    margin-right: 2vw;
}

.score-back {
    grid-row: 2 / 3;
    height: 100%;
    width: 98px;
}

.scoreboard-img {
    position: absolute;
    width: 100%;
}

@font-face {
    font-family: Cursed Timer;
    src: local('Larish Nueue'), url(../../fonts/CursedTimerUlil.ttf) format('truetype');
    font-style: normal;
    font-weight: normal;
}

.score-number {
    position: relative;
    display: block;
    top: 62px;
    left: 2px;

    font-family: Cursed Timer;
    font-size: 50px;
    line-height: 51px;
    text-align: center;
    letter-spacing: 0.025em;

    color: #464242;
}

.score-arrow {
    height: 100%;
}

.score-arrow:hover {
    cursor: pointer;
}

.arrow-up {
    grid-row: 1 / 2;
}

.arrow-down {
    grid-row: 3 / 4;
    transform: rotate(180deg);
}

/* CSS for small screen */
@media screen and (max-width: 1050px) {
    .scoreboard {
        left: 10vw;
    }
}

@media screen and (max-width: 825px) {
    .scoreboard {
        display: none;
    }
}