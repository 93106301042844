.linkbar-div {
    position: fixed;
    left: 30px;
    bottom: 15px;
}

.rack-image {
    position: absolute;
    width: 612px;
    transform: scale(.25);
    left: -225px;
    bottom: -280px;
}

.ball-row-image {
    position: absolute;
    visibility: hidden;
}

.link-ball {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.linkedin-div {
    left: 22px;
    top: -171px;
}

.instagram-div {
    position: absolute;
    left: 57px;
    top: -183px;
}

.github-div {
    left: 93px;
    top: -193px;
}

@media screen and (max-width: 1050px) {
    .rack-image {
        display: none;
    }

    .ball-row-image {
        visibility: visible;
        width: 200px;
        transform: scale(.25);
        left: -75px;
        top: -460px;
    }

    .linkedin-div {
        left: 0px;
        top: -190px;
    }
    
    .instagram-div {
        position: absolute;
        left: 0px;
        top: -125px;
    }
    
    .github-div {
        left: 0px;
        top: -60px;
    }

}

@media screen and (max-width: 825px) {
    .linkbar-div {
        display: none;
    }
}