html {  
    scroll-behavior: smooth;  
}

body {
    background-color: #E6BE99;
}

@font-face {
    font-family: Larish Neue;
    src: local('Larish Nueue'), url(../fonts/Larish\ Neue.ttf) format('truetype');
    font-style: normal;
    font-weight: normal;
}

.siteText {
    font-family: Larish Neue;
}

.siteTitle {
    font-size: 24px;
    line-height: 160%;
    color: #FFEFE0;
}

.siteParagraph {
    width: 85%;
    max-width: 680px;

    font-size: 18px;
    line-height: 160%;
    /* or 29px */

    color: #464242;
}

.siteButton {
    border: 2px solid #FFEFE0;
    box-sizing: border-box;
    border-radius: 9px;
    background-color: #E6BE99;

    font-family: Larish Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #FFEFE0;
}

.siteButton:hover {
    color: #E6BE99;
    background-color: #FFEFE0;
    cursor: pointer;
}

.scrollable {
    position: fixed;
    width: calc(80vw - 130px);
    height: 85vh;
    top: 10vh;
    left: calc(20vw + 130px);

    display: grid;
    grid-auto-rows: 85vh;
    row-gap: 15vh;

    overflow-y: scroll;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
}

.homepage-card {
    position: relative;
    width: 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    padding-top: 45px;
}

/* CSS for small screen */
@media screen and (max-width: 1050px) {
    .scrollable {
        width: calc(90vw - 130px);
        left: calc(10vw + 130px);
    }
}

@media screen and (max-width: 825px) {
    .scrollable {
        width: 90vw;
        left: 10vw;
    }

    .homepage-card {
        padding-top: 25px;
    }
}