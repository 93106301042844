.siteText {
    display: block;
}

.welCard {
    display: flex;
    flex-direction: column;
    row-gap: 3vh;
}

.welcIntro {
    font-size: 18px;
    line-height: 190%;
    color: #464242;
}

.welcName {
    font-size: 90px;
    white-space: nowrap;

    color: #FFEFE0;
}

.currLocation {
    text-decoration: none;
    color: #6B5BBC;
}

.email {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 40px;

    font-size: 18px;
    line-height: 160%;
    color: #464242;
}

.email-addr::after {
    content: "e: timothythomas1999@gmail.com";
}

.copyLine {
    position: relative;
    height: 2vh;

    border-left: 1px solid #464242;
}

#copyVector {
    position: relative;
    cursor: pointer;
}

/* CSS for small screen */
@media screen and (max-width: 1050px) {
    .welcName {
        font-size: 70px;
    }
}

@media screen and (max-width: 700px) {
    .welcName {
        font-size: 11vw;
    }

    .email {
        column-gap: 12px;
    }

    .email-addr::after {
        content: "copy_email( ) ";
    }

    .copyLine {
        display: inline;
        height: auto;

        border-left: 0px;
        color: #464242;
    }
    
    .copyLine::after {
        content: "=";
    }

}