#skill-board-img {
    position: absolute;
    max-width: 90%;
}

/* CSS for small screen */
@media screen and (max-width: 825px) {
    #skill-board-img {
        max-height: 75%;
        content: url("../../../components/skills/SkillCourtSide.png");
    }
}
