.contact-buttons {
    display: flex;
    flex-direction: row;
    column-gap: 30px;

    margin-top: 30px;
}

.emailButton {
    width: 100px;
    height: 40px;
}

.linkedInButton {
    width: 100px;
    height: 40px;
}
.credits {
    position: relative;
    top: 50px;
    font-size: 12px;

    color: #b9926e;
}
