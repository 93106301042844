.siteText {
    display: block;
}

/* Overarching Sections */
.exp-div {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    row-gap: 0px;
}

.exp-collegiate {
    position: relative;
    /* background-color: aquamarine; */
}

/**** Scorecard Section ****/

.score-card {
    position: relative;
    width: fit-content;
}

/* Company Buttons */
.buttonGrid {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    top: 0px;
    height: 40px;
    width: 480px;
}

.zswitch {
    z-index: 3;
    width: 100%;
    height: 100%;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

/* Card Information */
.score-info {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    
}

.card-stats {
    position: absolute;
    width: 90%;
    height: 23px;
    left: 4%;
    top: 90px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(70, 66, 66, 0.08);
    border-radius: 6px;

    font-size: 14px;
    color: rgba(70, 66, 66, 0.5);   
}

.card-year {
    position: absolute;
    right: 6.5%;
    top: 50px;

    font-size: 14px;
    line-height: 160%;
    /* or 22px */

    text-align: right;
    
    color: #6B5BBC;
}

.card-title {
    position: absolute;
    left: 5%;
    top: 130px;

    font-size: 22px;
    line-height: 160%;

    color: #464242;
}

.card-team {
    position: absolute;
    left: 5%;
    top: 160px;

    font-size: 16px;
    line-height: 160%;
    /* or 29px */

    color: #464242;
}

.card-description {
    position: relative;
    width: 83%;
    left: 5%;
    top: 165px;

    font-size: 14px;
    line-height: 140%;
    /* or 29px */

    color: #464242;
}

/* Collegiate Sections */

.coll-title {
    position: absolute;
    left: 230px;
    top: 45px;

    font-size: 16px;
    color: #464242;
}

.coll-info {
    position: absolute;
    left: 230px;
    top: 66px;

    font-size: 14px;
    color: rgba(70, 66, 66, 0.75);

}

#rafters {
    width: 713px;
}

/* CSS for small screen */
@media screen and (max-width: 1050px) {

    #score-card-img, #rafters{
        width: 70vw;
    }

    .buttonGrid {
        height: 4.2vw;
        width: 47vw;
    }

    .card-stats {
        top: 9vw;
        height: 2.4vw;
    }

    .card-year {
        top: 5.5vw;
    }

    .card-title {
        top: 13vw;
    }
    
    .card-team {
        top: 16vw;
    }

    .card-description {
        top: 17vw;
    }

    .coll-title {
        left: 23vw;
        top: 4.5vw;
    }

    .coll-info {
        left: 23vw;
        top: 6.7vw;
    }
}

@media screen and (max-width: 825px) {

    #score-card-img, #rafters {
        width: 80vw;
    }

    .card-stats {
        font-size: 1.3vw;
    }

    .card-year {
        font-size: 1.3vw;
    }

    .card-title {
        font-size: 3vw;
    }
    
    .card-team {
        top: 17vw;
        font-size: 1.9vw;
    }

    .card-description {
        font-size: 1.9vw;
        width: 75%;
    }

    .coll-title {
        left: 27vw;
        top: 4.5vw;
        font-size: 1.9vw;
    }

    .coll-info {
        left: 27vw;
        top: 7vw;
        font-size: 1.9vw;
    }
}
